import React from 'react'
import { graphql, Link } from "gatsby"
import tw, { styled } from 'twin.macro'

import Layout from "../components/Layout"
import Section from '../components/Section'

const TopHeader = styled.div`
    ${tw`
        pt-24 
        text-left 
        w-full
    `}
`

// This takes on the size of an h3 as per the custom global styles but every page needs to have an h1 for SEO optimization
const Title = styled.h1`
    ${tw`
        font-bold
        mb-6

        text-4xl sm:text-5xl
        leading-tight sm:leading-tight   
    `}
`

const ItemsContainer = styled.div`
    ${tw`
        w-full
    `}
`

const Item = styled.div`
    ${tw`
        pb-16
    `}

    :last-child {
        ${tw`
            pb-0
        `}
    }

    > h6 {
        ${tw`
            font-semibold
        `}
    }

    > p {
        ${tw`
            mb-6 text-lg
        `}
    }

    > img {
        ${tw`
            mb-6 
            max-w-full md:max-w-xl
            shadow-xl
        `}
    }
`

const ItemLinkExternal = styled.a`
    ${tw`
        underline hover:no-underline
        text-lg
    `}

    color: darkorange;
`

const ItemLinkInternal = styled(Link)`
    ${tw`
        underline hover:no-underline
        text-lg
    `}

    color: darkorange;
`


const MainPageOne = ({
    data: {
        markdownRemark: {
            frontmatter: { seo, title, subtitle, items }
        },
    }
}) => {
    return (
        <Layout title={seo.title} description={seo.description}>
            <Section>
                <TopHeader>
                    <Title>{title}</Title>
                    <h5>{subtitle}</h5>
                </TopHeader>
            </Section>

            {items && (
                <Section>
                    <ItemsContainer>
                        {items.map(({ title, body, url, image }, itemIdx) => (
                            <Item key={itemIdx}>
                                <h6>
                                    {title}
                                </h6>
                                <p>{body}</p>
                                {image && (
                                    <img src={image.relativePath ? `/img/${image.relativePath}` : image} alt={`${title} image post`} />
                                )}
                                {url &&
                                    (url.startsWith('http') ?
                                        (
                                            <ItemLinkExternal
                                                href={url}
                                                target="_blank"
                                            >
                                                Read more &#8594;
                                            </ItemLinkExternal>
                                        ) : (
                                            <ItemLinkInternal
                                                to={url}
                                            >
                                                Read more &#8594;
                                            </ItemLinkInternal>
                                        )
                                    )
                                }
                            </Item>
                        ))}
                    </ItemsContainer>
                </Section>
            )}

        </Layout>
    )
}

export default MainPageOne

export const pageQuery = graphql`
    query mainPageThreeById($id: String!) {
        markdownRemark(id: { eq: $id }) {
            frontmatter {
                seo {
                    title 
                    description
                }
                title
                subtitle
                items {
                    title
                    body
                    url
                    image {
                        relativePath
                    }
                }
            }
        }
    }
`